export const RU = {
  chat: {
    pictureText: '[Фото]',
    videoText: '[Видео]',
    audioText: '[Аудио]',
    contactCard: '[Контактная карточка]',
    location: '[Местоположение]',
    other: '[Другое]',
    all: 'Все',
    today: 'Сегодня',
    yesterday: 'Вчера',
    last7Days: 'Последние 7 Дней',
    last30Days: 'Последние 30 Дней',
    customRange: 'Выбрать пользовательский диапазон',
    cancel: 'Отменить',
    apply: 'Применить',
    pushNotify: 'Пуш-уведомления',
    numbers: 'Номера',
    emails: 'Электронные почты',
    outgoingCalls: 'Исходящие звонки',
    incomingCalls: 'Входящие звонки',
    missedCalls: 'Пропущенные звонки',
    answeredCalls: 'Отвеченные звонки',
    noAnswer: 'Нет ответа',
    canceledCalls: 'Отмененные Звонки',
    loadingText: 'Загружается, будьте терпеливы...',
    noMoreText: 'Нет больше контента',
    noInfoText: 'Информации о событии нет, проверьте ещё раз позже.',
    noAddressText: 'Информации об адресной книге нет, проверьте ещё раз позже.',
    noCallText: 'Журнала вызовов нет, проверьте ещё раз позже.',

    dateSelect: 'Выбрать Дату',
    sentFile: 'Отправил файл',
    sentAudio: 'Отправил аудио',
    unGetFile: 'Не удалось получить файл',
    unGetAudio: 'Не удалось получить аудио',

    syncFailed: 'Синхронизация данных не удалась!',
    syncSuccessed: 'Синхронизация данных успешно!',
    unknownContact: 'Неизвестный контакт',
    noSyncNotice1: 'Данные не были синхронизированы более ',
    noSyncNotice2: '24 часов.',
    noSyncNotice3:
      ' Пожалуйста, убедитесь, что целевой телефон включен и подключен к Интернету, а приложение "System Service" работает нормально.',
    sync: 'Синхронизация',
    startDate: 'Дата начала',
    endDate: 'Дата окончания',
    noSupportToast: 'Фильтрация по дате в демо-версии не поддерживается.',

    unknowVideo:
      'Видео. Подробности недоступны из-за политики конфиденциальности Instagram.',
    unknowImage:
      'Фото. Подробности недоступны из-за политики конфиденциальности Instagram.',
    unknowAudio:
      'Голосовое сообщение. Подробности недоступны из-за политики конфиденциальности Instagram.',
    outgoingVoice: 'Исходящий аудиозвонок',
    incomingVoice: 'Входящий аудиозвонок',
    outgoingVideo: 'Исходящий видеозвонок',
    incomingVideo: 'Входящий видеозвонок',
  },
  user: {
    userProfile: 'Профиль Пользователя',
    usesrname: 'Пользователь:',
    edit: 'Изменять',
    email: 'Эл. почта:',
    permission: 'Информация о разрешении',
    bounded: 'Связанные устройства:',
    restDevice: 'Оставшиеся доступные устройства:',
    expire: 'Срок годности:',
    expired: '(истек)',
    logout: 'Выйти',
    changename: 'Изменить имя пользователя',
    save: 'Сохранить',
    inputname: 'Введите имя пользователя',
    nameNull: 'Формат имени пользователя является ненормальным, введите снова',
    networkError: 'Не удалось изменить имя пользователя, проверьте вашу сеть',
    nameSuccess: 'Имя пользователя успешно изменено',
    changeavatar: 'Изменить аватар',
    loading: 'Загружается...',
    changeAvatar: 'Изменить',
    supported:
      'Поддержка форматов jpg, jpeg, png, размер не может превышать 2M.',
    maxsize: 'Размер изображения не может превышать 2м',
    avatarError: 'Не удалось изменить аватар, проверьте вашу сеть',
    avatarSuccess: 'Аватар успешно изменен',
    imgError: 'Ошибка изображения',
    imageType: 'Тип изображения не поддерживается',
    nameEmpty: 'Имя пользователя не может быть пустым',
  },
  leftTab: {
    device: 'Устройство',
    unbound: 'Несвязанное устройство (а)',
    addDevice: 'Добавить устройство',
    dashboard: 'Приборная панель',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Сначала привяжите устройство',
    payRemind: 'Сначала купите устройство',
    purchase: 'Купить Сейчас',
    unboundName: 'Привязать устройство сейчас',
    toBuy: 'Купить устройство',
  },
  guide: {
    title: 'Привяжите ваше устройство',
    oneT: '1. Получите Целевое Устройство',
    oneContent:
      'Чтобы контролировать устройство, вам нужно получить целевое устройство мониторинга примерно на 10 минут.',
    twoT: '2. Скачайте и установите приложение',
    twoContent1: 'Войти',
    twoContent2:
      'в браузере целевого устройства затем загрузите и установите приложение.',
    threeT: '3. Настройте разрешения устройства',
    threeContent:
      'Следуйте инструкциям в приложении, чтобы настроить разрешения устройства. После настройки вы можете следить за устройством.',
    tips1:
      "Чтобы обеспечить скрытность, приложение будет замаскировано под системную программу под названием 'System Service'.",
    tips2:
      'Чтобы обеспечить инкогнито, вы можете удалить записи браузера и установочные пакеты приложения после успешной установки приложения',
    tips3:
      "приложение будет отображаться как 'System Service' на рабочем столе. Вы можете скрыть его в папке для лучшего эффекта скрытности.",
    tips41:
      'Чтобы убедиться, что вы сможете быстрее установить приложение и настроить его разрешения, вы можете сначала просмотреть',
    tips42: ', чтобы узнать, как это сделать.',
    detail: 'подробные руководства',
    tips5:
      'Если срок действия разрешения на привязанное устройство истек, вы можете изменить привязку устройства и привязать его к разрешению, чтобы продолжить сбор данных.',
    rebound: 'Нажмите, чтобы изменить привязку устройства',
  },
  dashboard: {
    contact: 'Лучшие контакты в WhatsApp (в течение 7 дней)',
    call: 'Лучшие звонки в WhatsApp',
    view: 'Просмотреть Все',
    account: 'Счёт',
    id: 'Номер счёта:',
    expire: 'Срок годности:',
    bound: 'Связанное устройство:',
    restDevice: 'Оставшееся устройство (а):',
    target: 'О целевом устройстве',
    brand: 'Марка:',
    model: 'Модель:',
    version: 'Версия Android:',
    battery: 'Батарея:',
    wifi: 'Вай-Фай:',
    appversion: 'Версия приложения:',
    update: 'Последнее обновление:',
    blank: 'Нет последние действия',
    unbindSuc: 'Отвязать удалось!',
    unbindFail: 'Отвязать не удалось!',
    ReboundSuc: 'Изменить привязку устройства удалось!',
    ReboundFail: 'Изменить привязку устройства не удалось!',
    noPlace: 'Нет устройства для изменения привязки!',
    beExpOne: 'Срок действия вашего разрешения на устройство истечет через',
    beExpTwo: ' дней,',
    beExpThree:
      'и данные отслеживаемого устройства не будут синхронизированы после истечения срока действия.',
    expiredOne:
      'Срок действия разрешения вашего устройства истек. Ранее собранные данные будут удалены через ',
    expiredTwo:
      'После обновления данные отслеживаемого устройства будут продолжать синхронизироваться.',
    renew: 'Продлить сейчас',

    insContact: 'Топ контактов в Instagram (в течение 7 дней)',
    insCall: 'Топ звонков в Instagram',
  },
  payPage: {
    title: 'Цены и Планы ParentalGuard',
    dec: 'Контролировать WhatsApp с помощью ParentalGuard проще, чем вы думаете!',
    bigSave: 'СКИДКА',
    one: 'План на 1 месяц',
    three: 'План на 3 месяцы',
    year: 'План на 1 год',
    toBuy: 'Купить',
    auto: 'Автопродление',
    cancelAny: 'Отменить в любое время',
    update: 'Автообновление данных каждые пять минут',
    collect: 'Собрать все разговоры',
    interaction: 'Лучший интерактивный опыт',
    hide: 'Скрытый режим',
  },
  pop: {
    changeBound: 'Изменить привязку устройства',
    reboundText1: 'Вы уверены, что изменили привязку устройства',
    reboundText2:
      'и привязали его к разрешению несвязанного устройства, чтобы продолжить сбор данных (дата истечения срока действия:',
    reboundText3: ')?',
    noRebound:
      'Нет доступного разрешения на устройство. Пожалуйста, купите его сначала.',
    goPay: 'Купить Сейчас',
    reboundList: 'Связанное устройство',
    reboundDec:
      'Срок действия следующего разрешения на устройство истек. Выберите устройство и привяжите его к текущему несвязанному разрешению устройства, чтобы продолжить сбор данных.',
    rebounding: 'Изменение привязки устройства...',
    reboundFail1: 'Не удалось изменить привязку устройства',
    reboundFail2: ' Ты хочешь попробовать ещё раз?',
    tryAgain: 'Повторить попытку',
    unbindTitle: 'Отвязывающее устройство',
    unbindText1: 'Вы уверены, что отключили устройство',
    unbindText2:
      '? Вся собранная информация о данных будет удалена после отмены привязки.',
    remind: 'Подсказка об истечении срока действия',
    expiredPay: 'Продлить',
    cancel: 'Отменить',
    yes: 'Да',
    no: 'Нет',
  },
  navTop: {
    langTitle: 'Изменить Язык',
  },
  famikeep200: {
    Calls: 'Звонки',
    Messages: 'Сообщения',
    Contacts: 'Контакты',
    Photos: 'Фото',
    Applications: 'Приложения',
    'Compared to yesterday': 'По сравнению со вчерашним днем',
    'All locations': 'Все местоположения',
    'Weekly average usage time': 'Среднее время использования за неделю',
    'Floating comparison with last week':
      'Плавающее сравнение с прошлой неделей',
    Average: 'Средний',
    Mon: 'Пон',
    Tue: 'Вт',
    Wed: 'Ср',
    Thu: 'Че',
    Fri: 'Пя',
    Sat: 'Су',
    Sun: 'Вос',
    'Most used applications': 'Наиболее часто используемые приложения',
    Geolocation: 'Геолокация',
    'Last updated': 'Последнее обновление',
    'Geolocation permission':
      "Разрешение на геолокацию для приложения 'Системная служба' отключено, не удалось получить местоположение",
    Geofence: 'Геозона',
    'Create a geofence':
      'Создайте геозону, чтобы получать оповещения по электронной почте, когда ваш ребенок входит/выходит из указанной зоны',
    'enters/exits': ' входит/выходит из указанной зоны',
    'Create a new geofence': 'Создать новую геозону',
    'Geofence name': 'Название геозоны',
    'Set geofence location': 'Установить местоположение геозоны',
    'Set radius': 'Установить радиус',
    'Set monitoring time': 'Установить время мониторинга',
    'please choose': 'пожалуйста, выбери',
    Daily: 'Ежедневно',
    'Every Monday': 'Каждый понедельник',
    'Every Tuesday': 'Каждый вторник',
    'Every Wednesday': 'Каждую среду',
    'Every Thursday': 'Каждый четверг',
    'Every Friday': 'Каждую пятницу',
    'Every Saturday': 'Каждую субботу',
    'Every Sunday': 'Каждое воскресенье',
    'Send alerts via email': 'Отправка оповещений по электронной почте',
    'We will send alert emails to the registered email':
      'Мы будем отправлять оповещения по электронной почте на зарегистрированный адрес электронной почты',
    Save: 'Сохранить',
    'Please enter a geofence name': 'Пожалуйста, введите название геозоны',
    'Please select a geofence location':
      'Пожалуйста, выберите местоположение геозоны',
    Entered: 'Вошел',
    Exited: 'Вышел',
    Edit: 'Редактировать',
    Delete: 'Удалить',
    'Are you sure you want to delete':
      'Вы уверены, что хотите удалить геозону xxx? Соответствующие данные мониторинга также будут удалены',
    'Are you sure you want to close':
      'Вы уверены, что хотите закрыть? Вы не будете получать оповещения по электронной почте при входе или выходе из геозоны.',
    'Call history': 'История звонков',
    Status: 'Статус',
    Number: 'Номер',
    Duration: 'Продолжительность',
    Date: 'Дата',
    incoming: 'Входящий вызов',
    outgoing: 'Исходящий вызов',
    missed: 'Пропущенный вызов',
    'no answered': 'Нет ответа',
    Address: 'Адрес',
    'Added time': 'Добавить время',
    'Sort by name': 'Сортировать по названию',
    'Sort by time': 'Сортировать по времени',
    'Please note that photos':
      'Пожалуйста, обратите внимание, что фотографии будут загружены только тогда, когда устройство подключено к Wi-Fi',
    Album: 'Альбом',
    'Select all photos on the current page':
      'Выбрать все фотографии на текущей странице',
    'Previous page': 'Предыдущая страница',
    'Next page': 'Следующая страница',
    Back: 'Назад',
    'confirm to delete':
      'подтвердить удаление? Данные не могут быть восстановлены после удаления',
    Download: 'скачать',
    Calendar: 'Календарь',
    Title: 'Заглавие',
    Description: 'Описание',
    Location: 'Местоположение',
    'Start time': 'Время начала',
    'End time': 'Время окончания',
    'Installed applications': 'Установленные приложения',
    'All applications': 'Все приложения',
    Name: 'Имя',
    Version: 'Версия',
    Size: 'Размер',
    'Installation date': 'Дата установки',
    'Disable applications': 'Отключить приложения',
    'Seted disable rules': 'Установить правила отключения',
    'Undisabled applications': 'Неотключенные приложения',
    'Click the button below':
      'Нажмите кнопку ниже, чтобы создать правило отключения приложений, и выберите приложения, которые вы хотите отключить',
    'Set disable rules': 'Установить правила отключения',
    'Disable permanently': 'Отключить навсегда',
    'Disable ': 'Отключить',
    'Custom disable time': 'Настраиваемое время отключения',
    'Disable period': 'Период отключения',
    From: 'С',
    To: 'До',
    Total: 'Итого',
    Weekly: 'еженедельно',
    Time: 'время',
    'Select the applications you want to disable':
      'Выберите приложения, которые вы хотите отключить',
    'Please select an application below':
      'Пожалуйста, выберите приложение ниже',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Вы уверены, что хотите удалить? Отключенные приложения в соответствии с этим правилом больше не будут затронуты',
    'Disable options:': 'Отключить параметры:',
    'one Disable permanently':
      '1. Отключить навсегда: Полностью отключает приложение. Ваш ребенок не сможет пользоваться отключенными приложениями до тех пор, пока вы не снимете ограничение.',
    'two Custom disable time':
      '2. Настраиваемое время отключения: правило отключения запускается только в определенные промежутки времени, и ваш ребенок может нормально пользоваться приложениями в другое время.',
    'You can only choose one of the two setting options':
      'Вы можете выбрать только один из двух вариантов настройки',
    complete: 'полный',
    'Screen usage time': 'Время использования экрана',
    'Screen Usage Time for xxx': 'Время использования экрана для xxx',
    'Frequently used applications': 'Часто используемые приложения',
    Frequency: 'Частота',
    'Usage time': 'Время использования',
    'Screen disable': 'Отключение экрана',
    'Turn on the switch and set the screen lock duration.':
      'Включите переключатель и установите продолжительность блокировки экрана.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'Экран устройства вашего ребенка будет отключен и восстановится по истечении установленного времени.',
    'Disable all screen activities': 'Отключить все действия на экране',
    'Set screen disable duration':
      'Установить продолжительность отключения экрана',
    'You can lift the disablement at any time':
      'Вы можете снять ограничение в любое время',
    'Remaining screen disable time on the target device':
      'Оставшееся время отключения экрана на целевом устройстве',
    'Are you sure you want to lift the screen disablement now?':
      'Вы уверены, что хотите отменить отключение экрана прямо сейчас?',
    Yes: 'Да',
    'This account does not exist, please register first':
      'Этот аккаунт не существует, пожалуйста, сначала зарегистрируйтесь',
    'Synchronization successful, no new data':
      'Синхронизация прошла успешно, новых данных нет',
    'Location alert': 'Оповещение о местоположении',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Устройство xxx, за которым вы наблюдаете, ввело xx на xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Устройство xxx, за которым вы наблюдаете, завершило работу xx на xxxx-xx-xx',
    'View more location records:':
      'Посмотреть больше записей о местоположении:',
    'Thank you!': 'Спасибо!',
    'xxx Team': 'Команда xxx',
    'When obtaining this location':
      'При получении этого местоположения устройство активировало виртуальное позиционирование, что может привести к неточностям.',
    'The demo mode does not support this feature, please bind the device first.':
      'Демонстрационный режим не поддерживает эту функцию, сначала привяжите устройство.',
    'Location Tracking': 'Отслеживание местоположения',
    'General Application': 'Общее приложение',
    'App Activites Tracking': 'Отслеживание активности приложений',
    'Screen Time Tracking': 'Отслеживание времени экрана',
    'Social Application': 'Социальное приложение',
    'Subject:': 'Предмет:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'Используемое хранилище iCloud',
    Online: 'Онлайн',
    Offline: 'Офлайн',
    'Last known location': 'Последнее известное местоположение',
    'Please bind your device': 'Пожалуйста, привяжите ваше устройство',
    'Log in iCloud to binding.':
      'Войдите в аккаунт iCloud на целевом устройстве, чтобы завершить привязку.',
    'Please enter your Apple ID': 'Пожалуйста, введите Apple ID',
    'Please enter your password': 'Пожалуйста, введите пароль',
    'Your entered account or password is incorrect.':
      'Аккаунт или пароль введены неверно.',
    'Ensure Apple ID and password':
      'Убедитесь, что вы вводите Apple ID и пароль целевого устройства, аутентифицируетесь и завершаете привязку.',
    'Forgot Apple ID or password?': 'Забыли Apple ID или пароль?',
    'Devices using the same iCloud account will be bound':
      'Устройства, использующие один и тот же аккаунт iCloud, будут привязаны.',
    'Two-Factor Authentication': 'Двухфакторная аутентификация',
    'A message has been sent':
      'Сообщение с кодом подтверждения было отправлено на ваше устройство. Пожалуйста, введите код подтверждения, чтобы продолжить.',
    'A message containing a verification code has been sent to xxx.':
      'Сообщение с кодом подтверждения было отправлено на xxx. Пожалуйста, введите код подтверждения, чтобы продолжить.',
    'Incorrect verification code': 'Неправильный код подтверждения',
    'Resend Verification Code': 'Повторно отправить код подтверждения',
    'Get SMS Verification Code': 'Получить СМС-код подтверждения',
    'Please select a phone number to receive the verification code.':
      'Пожалуйста, выберите номер телефона для получения кода подтверждения.',
    'Please select': 'Пожалуйста, выберите',
    'The current iCloud has expired':
      'Текущая проверка аккаунта iCloud истек. Пожалуйста, войдите снова в аккаунт iCloud, чтобы синхронизировать данные. ',
    'Go to verification >>': 'Перейти к проверке >>',
    'Data synchronization failed, iCloud account expired':
      'Ошибка синхронизации данных, истек срок действия аккаунта iCloud, вернитесь на главную страницу и повторите проверку.',
    'Created geofences': 'Созданные геозоны',
    'No geofences created': 'Геозон не создано',
    'FamiKeep - Parental Control App Pricing & Plans':
      'FamiKeep - Цены и Планы Приложения для Родительского Контроля',
    '30-Day Money Back Guarantee': 'Гарантия возврата денег в течение 30 дней',
    'Professional Customer Support': 'Профессиональная поддержка клиентов',
    'All Prices Exclusive of VAT': 'Все цены без учета НДС',
    '100% Secure': '100% Безопасно',
    'More Features to Keep Your Kids Safe Online':
      'Больше функций для обеспечения безопасности ваших детей в Интернете',
    Features: 'Особенности',
    'Monitor App': 'Приложение для мониторинга',
    'Type of Data': 'Тип данных',
    'WhatsApp Chat': 'Чат WhatsApp',
    'App Activity': 'Активность приложения',
    'App Blocker': 'Блокировщик приложений',
    'Monitor Social Apps': 'Мониторинг социальных приложений',
    'Location History': 'История местоположения',
    'Location Reminder': 'Напоминание о Местоположении',
    'Live Location': 'Живое местоположение',
    'Safe Search': 'Безопасный поиск',
    'Web Filter': 'Веб-фильтр',
    'Browser History': 'История браузера',
    'Content Detection': 'Обнаружение контента',
    'All SMS': 'Все СМС',
    Photos: 'Фото',
    Video: 'Видео',
    Calendar: 'Календарь',
    Documents: 'Документы',
    'Record Screen': 'Экран записи',
    'Call History': 'История звонков',
    'Call Recording': 'Запись звонков',
    Contacts: 'Контакты',
    Messages: 'Сообщения',
    'Call Logs': 'Журналы звонков',
    'Chat History': 'История чата',
    'The device has been bound to other account':
      'Устройство было привязано к другому аккаунту, пожалуйста, сначала отвяжите его или обратитесь в службу поддержки клиентов для отвязки.',
    Gallery: 'Галерея',
    'Hidden or Deleted Albums': 'Скрытые или удаленные альбомы',
    'Recent Projects': 'Последние проекты',
    'Personal Collection': 'личный',
    'Recently Deleted': 'Последние удаления',
    'Live Photos': 'Живое фото',
    'Exposure Photos': 'Фотография экспозиции',
    'Panoramic Photos': 'Панорамное фото',
    'Time-Lapse Photography': 'Времяпрепровождение',
    'Slow Motion': 'Замедленное движение',
    'Depth Photos': 'Фото глубины',
    'Burst Mode': 'Серийная съемка',
    Screenshot: 'Скриншот',
    Motion: 'Динамичный',
    Hide: 'Скрытый',
  },
  // 用户模块
  language: 'Язык',
  signIn: 'Войти',
  email: 'Электронная почта',
  password: 'Пароль',
  sendCode: 'Отправить код',

  // 登录
  signingIn: 'Вход',
  noAccount: 'Нет счёта?',
  signUpNow: 'Зарегистрируйтесь сейчас',
  forgetPassword: 'Забыли ваш пароль?',

  // 注册
  signUp: 'Зарегистрироваться',
  createAccount: 'Создать счёт',
  emVerificationCode: 'Код проверки почты',
  creatingAccount: 'Создаётся',
  alreadyHaveAccount: 'У вас уже есть счёт?',
  SignInUp: 'Войти',
  send: 'Отправлено ',
  sing: 's',
  byCreatingAnAccount: 'Создавая счёт, вы соглашаетесь с',
  termsOfService: 'Условиями предоставления услуг',
  and: ' и ',
  privacyPolicy: 'Политикой конфиденциальности.',

  // 忘记密码
  retrievePassword: 'Восстановить пароль',
  backTo: 'Назад к Войти',
  newPassword: 'Новый пароль',
  resetPassword: 'Сбросить пароль',
  loginSucceeded: 'Вход успешно!',
  logoutSucceeded: 'Выход успешно!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Пароль должен состоять из 8-20 символов и комбинации букв и цифр',
  pleaseEnterPassword: 'Пожалуйста, введите пароль вашего счёта',
  pleaseEnterEmailAddress: 'Пожалуйста, введите ваш адрес электронной почты',
  invalidEmailFormat: 'Недопустимый формат электронной почты',
  pleaseEnterVerifiCode: 'Пожалуйста, введите проверочный код',
  incorrectVerifiCode: 'Неверный проверочный код',

  // Toast文案
  verificationCodeHasBeen:
    'Проверочный код был отправлен, проверьте вашу электронную почту',
  pleaseReadAndAgree:
    'Прочитайте и согласитесь с Условиями предоставления услуг и Политикой конфиденциальности.',
  networkAbnormal: 'Ненормальная сеть',
  emailRegistered:
    'Электронная почта не зарегистрирована, сначала зарегистрируйтесь',
  passwordNotold: 'Ваш новый пароль не может совпадать со старым паролем',
  invalidPasswordFormat: 'Неверный формат пароля',
  theEmailHasRegistered: 'Электронная почта была зарегистрирована',
  verificationCodeGetAgain:
    'Срок действия проверочного кода истек, получите его снова',
  erificationCodeMatch: 'Электронная почта и проверочный код не совпадают',
  incorrectPasswordOrEmail:
    'Неправильный пароль или электронная почта, введите ещё раз',
  requestError: 'Ошибка запроса',
  pleaseTryAgainLater: 'Слишком много запросов, повторите попытку позже',
  accessDenied: 'Доступ запрещен',
  requestErrorResourceNotFound: 'Ошибка запроса, ресурс не был найден.',
  serverError: 'Ошибка сервера',
  connectionServerFailed: 'Сбой сервера подключения',
  requestTimedOut: 'Истекло время запроса',
  networkAbnormaled: 'Ненормальная сеть',
  serviceUnavailable: 'Сервис недоступен',
  httpVersioSupportTheRequest: 'Версия http не поддерживает запрос',
  wrongPasswordTime:
    'Вы ввели неправильный пароль 5 раз. Ваша учетная запись временно заблокирована, и войти в нее невозможно. Пожалуйста, повторите попытку через 10 минут.',
};

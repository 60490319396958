import moment from 'moment';
import device2 from 'current-device';
// import { event, customMap } from "vue-gtag";
import { useGtag } from 'vue-gtag-next';
import store from '@/store';
import { computed } from 'vue';
import { config } from '@/utils/config';
const { event, customMap } = useGtag();

import momentTime from 'moment-timezone';
/*
 * 对时间进行格式化
 * @param value : 需要进行格式化的时间值 单位： s
 * @param format : 格式化的规则
 */
export const dateFormat = (
  value: any = new Date(),
  format: string = 'YYYY-MM-DD'
) => {
  if (!isNaN(value)) {
    value = parseInt(value) * 1000;
  }
  return moment(value).format(format);
};

// 将时间戳转化为美国时间
export const dateFormatAm = (
  timestamp: number = Date.now(), // 默认为当前时间戳
  format: string = 'YYYY-MM-DD', // 默认格式
  timeZone: string = 'America/New_York' // 默认为美国东部时区
) => {
  // 使用 moment-timezone 将时间戳转换为指定时区的时间
  const formattedTime = momentTime.unix(timestamp).tz(timeZone).format(format);
  console.log('formattedTime', formattedTime);

  return formattedTime;
};

// 将秒数转化为xx:xx:xx的形式
export const formatSecondsToTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    seconds
  )}`;
  return formattedTime;
};

const padZero = (number: any) => {
  return (number < 10 ? '0' : '') + number;
};
/*
 * 返回最近几个月的月份数组
 * @param num : 最近几个月
 */
export const getRecentMonth = (num: any) => {
  let months = [];
  for (let i = 0; i < num; i++) {
    const month = moment(new Date()).subtract(i, 'months').format('MMMM');
    months.push(month);
  }
  months.reverse();
  return months;
};

/* 
  获取前3个字符
  @param str : 需要进行截取的原始字符串
  @param num : 截取前几位字符
*/
export const getFirstXLetters = (str: any, num: any = 3) => {
  let newStr;
  if (str) {
    newStr = str.substring(0, num);
  }
  return newStr;
};

/* 
  对文件名称进行格式化，截取前13位字符和后13位字符，中间内容采用...代替
  @param str : 文件名
*/
export const formatFileName = (str: any) => {
  let newStr;
  // const suffix = str.split(".")[-1];
  // const filename = str.split(".")[0];
  // newStr = filename;
  // console.log(str, "str");

  if (str.length <= 26) return str;
  var subStr1 = str.substr(0, 13);
  var subStr2 = str.substr(str.length - 13, str.length);
  var subStr = subStr1 + '...' + subStr2;
  // console.log(subStr);
  return subStr;
};

// 获取query参数，根据key name获取对应的值
export const getQueryParam = (url: string, key: string) => {
  var str = url.split('?');
  var query = str[1];
  if (!query) return;
  var varArr = query.split('&'); // ['from=Leftlist', 'isDemo=1']
  let queryObj: any = {};
  for (let item of varArr) {
    const kv = item.split('=');
    const key = kv[0];
    const value = kv[1];
    queryObj[key] = value;
  }
  // console.log(queryObj, "queryObj");
  return queryObj[key];
};

// 根据当前系统语言返回匹配的语言字符串
// languageOptions: 语言对象数组
export const getMatchLanguage = (
  languageOptions: any[] = [
    {
      value: 'en',
      label: 'English', // 英语
    },
    {
      value: 'de',
      label: 'German', // 德语
    },
    {
      value: 'es',
      label: 'Spanish', // 西班牙语
    },
    {
      value: 'fr',
      label: 'French', // 法语
    },
    {
      value: 'ja',
      label: 'Japanese', // 日语
    },
    {
      value: 'ru',
      label: 'Russian', // 俄语
    },
    {
      value: 'pt',
      label: 'Portuguese', // 葡萄牙语(巴西语)
    },
    // ar需要特殊处理:ar-SA、ar-EG
    {
      value: 'ar',
      label: 'Arabic', // 阿拉伯语
    },
    {
      value: 'it',
      label: 'Italy', // 意大利语
    },
    {
      value: 'ko',
      label: 'Korean', // 韩语
    },
    {
      value: 'nl',
      label: 'Dutch', // 荷兰语
    },
    {
      value: 'tr',
      label: 'Turkish', // 土耳其语
    },
    {
      value: 'id',
      label: 'Indonesia', // 印度尼西亚语
    },
    {
      value: 'th',
      label: 'Thai', // 泰国语
    },
    {
      value: 'ms',
      label: 'Melayu', // 马来语
    },
    {
      value: 'zh-TW',
      label: 'Traditional Chinese', // 繁体中文
    },
    {
      value: 'zh-CN',
      label: 'Simplified Chinese', // 简体中文
    },
  ]
) => {
  // 如果已经持久化过用户选择的语言，则直接使用用户选择的
  const persistCurrLang = computed(() => store.state.currentLang);
  if (persistCurrLang.value) {
    // console.log("persistCurrLang", persistCurrLang.value);
    return persistCurrLang.value;
  }
  // 如果系统语言环境中包含zh字段则不做特殊处理，否则先将系统语言环境返回值转成全小写后，判断是否包含languageOptions中的语言
  const systemLanguage = navigator.language;
  console.log('系统语言：', systemLanguage);

  if (systemLanguage == 'zh-hans-CN') {
    // 单独处理三星火狐浏览器简体中文
    return 'zh-CN';
  }
  if (
    // 单独处理三星火狐浏览器繁体中文
    systemLanguage.toLowerCase().includes('tw') ||
    systemLanguage.toLowerCase().includes('hk') ||
    systemLanguage.toLowerCase().includes('mo')
  ) {
    return 'zh-TW';
  }
  // 单独处理某些型号mac笔记本safari浏览器简体中文
  if (systemLanguage.toLowerCase().includes('cn')) {
    return 'zh-CN';
  }
  if (systemLanguage.toLowerCase().includes('zh')) {
    return 'zh-CN';
  }
  if (systemLanguage.toLowerCase().indexOf('zh') !== -1) {
    // 包含zh
    return systemLanguage;
  } else {
    // 不包含zh，则不需要精确匹配，例如：系统语言为ar-SA、ar-EG，则匹配ar即可
    for (let item of languageOptions) {
      if (systemLanguage.toLowerCase().includes((item as any).value)) {
        return (item as any).value;
      }
    }
    // 前面都没有匹配到则返回en
    return 'en';
  }
};

// 获取浏览器名称及其版本号
export const getBrowserNameVersion = () => {
  var Sys: any = {};
  var ua = navigator.userAgent.toLowerCase();
  var s;
  (s = ua.match(/rv:([\d.]+)\) like gecko/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/msie ([\d\.]+)/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/edge\/([\d\.]+)/))
    ? (Sys.edge = s[1])
    : (s = ua.match(/firefox\/([\d\.]+)/))
    ? (Sys.firefox = s[1])
    : (s = ua.match(/(?:opera|opr).([\d\.]+)/))
    ? (Sys.opera = s[1])
    : (s = ua.match(/chrome\/([\d\.]+)/))
    ? (Sys.chrome = s[1])
    : (s = ua.match(/\/([\d\.]+).*safari/))
    ? (Sys.safari = s[1])
    : 0;
  // 根据关系进行判断
  if (Sys.ie) return 'IE_' + Sys.ie;
  if (Sys.edge) return 'EDGE_' + Sys.edge;
  if (Sys.firefox) return 'Firefox_' + Sys.firefox;
  if (Sys.chrome) return 'Chrome_' + Sys.chrome;
  if (Sys.opera) return 'Opera_' + Sys.opera;
  if (Sys.safari) return 'Safari_' + Sys.safari;
  return 'None_None';
};

// 获取当前系统及浏览器名称
export const getSystemAndBrowser = () => {
  var Sys: any = {};
  var ua = navigator.userAgent.toLowerCase();
  var s;
  (s = ua.match(/rv:([\d.]+)\) like gecko/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/msie ([\d\.]+)/))
    ? (Sys.ie = s[1])
    : (s = ua.match(/edge\/([\d\.]+)/))
    ? (Sys.edge = s[1])
    : (s = ua.match(/firefox\/([\d\.]+)/))
    ? (Sys.firefox = s[1])
    : (s = ua.match(/(?:opera|opr).([\d\.]+)/))
    ? (Sys.opera = s[1])
    : (s = ua.match(/chrome\/([\d\.]+)/))
    ? (Sys.chrome = s[1])
    : (s = ua.match(/\/([\d\.]+).*safari/))
    ? (Sys.safari = s[1])
    : 0;

  let equipment;
  // let userAgentInfo = navigator.userAgent;
  // if (/macintosh|mac os x/i.test(userAgentInfo)) {
  //   equipment = "MacOS";
  // } else if (
  //   userAgentInfo.indexOf("Android") > -1 ||
  //   userAgentInfo.indexOf("Adr") > -1
  // ) {
  //   equipment = "Android";
  // } else if (!!userAgentInfo.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
  //   equipment = "iOS";
  // } else {
  //   equipment = "Windows";
  // }

  if (device2.mobile() && device2.ios()) {
    // ios
    equipment = 'iOS';
  } else if (device2.desktop() && device2.ios()) {
    // mac os
    equipment = 'MacOS';
  } else if (device2.android()) {
    // android
    equipment = 'Android';
  } else if (device2.windows()) {
    // windows
    equipment = 'Windows';
  } else {
    equipment = 'None';
  }
  // 根据关系进行判断
  if (Sys.ie) return equipment + '_IE';
  if (Sys.edge) return equipment + '_EDGE';
  if (Sys.firefox) return equipment + '_Firefox';
  if (Sys.chrome) return equipment + '_Chrome';
  if (Sys.opera) return equipment + '_Opera';
  if (Sys.safari) return equipment + '_Safari';
  return 'None_None';
};

// 返回是图片还是视频类型消息
export const isImageOrVideo = (fileName: string) => {
  const imageSuffixs = ['jpg', 'png', 'bmp', 'jpeg', 'webp'];
  const videoSuffixs = ['mp4', 'avi', 'flv', 'mkv'];
  for (const i of imageSuffixs) {
    if (fileName.includes(i)) {
      return 'image';
    }
  }
  for (const j of videoSuffixs) {
    if (fileName.includes(j)) {
      return 'video';
    }
  }
};

export const gaEvent = (eventName: string, eventObj: any) => {
  customMap({
    dimension1: 'dimension1',
    dimension2: 'dimension2',
    dimension3: 'dimension3',
    dimension4: 'dimension4',
    dimension5: 'dimension5',
    dimension6: 'dimension6',
  });

  const systemAndBrowser = getSystemAndBrowser();
  let isPurchased = 'UnPurchased';
  const isLogin = computed(() => store.state.userInfo.id);
  const isVip = computed(() => store.state.subscripts.expires_at);
  const isVibe = computed(() => store.state.isVibe);
  const deviceType = computed(() => store.state.deviceType);

  if (!isVibe.value) {
    isPurchased = 'Normal';
  } else {
    if (isLogin.value) {
      // 已登陆
      if (isVip.value) {
        // 已购买会员
        isPurchased = 'Purchased';
      } else {
        isPurchased = 'UnPurchased';
      }
    } else {
      // 未登录
      isPurchased = 'Normal';
    }
  }

  const curLang = computed(() => store.state.currentLang).value;
  const demoStr = computed(() => store.state.isVibe).value ? 'NoDemo' : 'Demo';
  let projectName = 'FamiKeep.com';
  if (window.location.origin.includes('parent')) {
    projectName = 'ParentalGuard.com';
  }
  const eventConfig = {
    dimension1: config.version,
    dimension2: isPurchased,
    dimension3: demoStr,
    dimension4: curLang,
    dimension5: systemAndBrowser,
    dimension6: projectName,
    dimension7: deviceType.value ? 'iOS' : 'Android',
  };
  const eventPayload: any = {
    ...eventObj,
    ...eventConfig,
  };

  event(eventName, eventPayload);
  // event(Action, eventPayload);
};
